import rbac from './rbac'
import report from './report'
import totalReport from './total-report'
import echart from './echarts'
import updateReport from './update-report'
import Review from './review'
import Settings from './settings'
import bbs from './bbs'
import system from './system'
import showCommand from './show-command'
import home from './home'
import userUperation from './user-operation'
import crm from './crm'
import game from './game'
import topup from './topup'
import share from './share'
import advance from './advance'
import fission from './fission'
import netRed from './net-red'
import log from './log'
import merchant from './merchant'
import membership from './membership'
import toolkit from './toolkit'
import dashboard from './dashboard'
// import crowd from './crowd'

const routes = []

routes.push(home)
routes.push(dashboard)
routes.push(report)
routes.push(totalReport)
routes.push(echart)
routes.push(updateReport)
routes.push(Review)
routes.push(Settings)
routes.push(rbac)
routes.push(bbs)
routes.push(system)
routes.push(showCommand)
routes.push(userUperation)
routes.push(crm)
routes.push(game)
routes.push(topup)
routes.push(share)
routes.push(advance)
routes.push(fission)
routes.push(netRed)
routes.push(log)
routes.push(merchant)
routes.push(membership)
routes.push(toolkit)
// routes.push(crowd)

export default routes
